import React, { useEffect } from 'react'
import { Modal } from 'bootstrap'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { OpenStreetMapProvider } from 'leaflet-geosearch'
/* eslint-disable react/prop-types */

// const provider = new AlgoliaProvider()
const provider = new OpenStreetMapProvider()
function SignUpForm({ onSubmit, lang = 'de' }) {
  const [submitDisabled, setSubmitDisabled] = React.useState(false)
  const [addressError, setAddressError] = React.useState(false)
  const [storeFormData, setStoreFormData] = React.useState(null)
  const [addressModal, setAddressModal] = React.useState(null)
  const addressModalRef = React.useRef()

  const getLocation = async (address) => {
    const results = await provider.search({
      query: address,
    })
    return results.length > 0 ? [results[0].y, results[0].x] : false
  }

  const handleSubmit = (person, { setFieldError }) => {
    setSubmitDisabled(true)
    if (addressError) setAddressError(false)
    let website = person['website'].toLowerCase()
    website =
      website.substr(0, 3) === 'htt'
        ? website
        : website.substr(0, 3) === 'www' ||
          (website.split('.') || []).length > 2
        ? `https://${website}`
        : `https://www.${website}`
    getLocation(
      person['street'] + ', ' + person['postcode'] + ' ' + person['city']
    ).then((result) => {
      if (result) {
        onSubmit({ geo: result, ...person, website })
      } else {
        addressModal.show()
        setStoreFormData(person)
        setSubmitDisabled(false)
      }
    })
  }

  const handleForceSubmit = () => {
    setSubmitDisabled(true)
    addressModal.hide()
    onSubmit(storeFormData)
  }

  const validationSchema = Yup.object().shape({
    company: Yup.string()
      .required('Dies ist ein Pflichtfeld')
      .min(2, 'Bitte den Geschäftsnamen eingeben.'),
    website: Yup.string(),
    email: Yup.string()
      .email('Bitte eine gültige E-Mail eingeben.')
      .required('Dies ist ein Pflichtfeld'),
    phone: Yup.string(),
    street: Yup.string()
      .required('Dies ist ein Pflichtfeld ')
      .min(3, 'Bitte Straße und Hausnummer eingeben'),
    postcode: Yup.string().required('Dies ist ein Pflichtfeld ').min(4),
    city: Yup.string().required('Bitte die Stadt angeben').min(2),
    termsOptin: Yup.bool().oneOf([true], 'Dies ist ein Pflichtfeld'),
  })

  useEffect(() => {
    setAddressModal(new Modal(addressModalRef.current))
  }, [])

  return (
    <div id="signup_form" className="mt-5">
      <div className="row">
        <div className="col">
          <h3 className="mb-4">Ihre Buchhandlung auf unserer Website</h3>
          <Formik
            initialValues={{
              company: '',
              website: '',
              email: '',
              phone: '',
              street: '',
              postcode: '',
              city: '',
              termsOptin: false,
            }}
            onSubmit={(values, errors) => {
              handleSubmit(values, errors)
            }}
            validationSchema={validationSchema}
          >
            {({
              values,
              errors,
              touched,
              handleSubmit,
              handleChange,
              handleBlur,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="row mb-4 pt-1 gy-4">
                  <div className="col">
                    <div className="input-group has-validation">
                      <input
                        type="text"
                        className={`form-control ${
                          touched.company && !!errors.company && 'is-invalid'
                        }`}
                        placeholder="Geschäftsbezeichnung"
                        value={values.company}
                        onChange={handleChange('company')}
                        onBlur={handleBlur('company')}
                      />
                      <div className="invalid-feedback">{errors.company}</div>
                    </div>
                  </div>
                </div>
                <div className="row mb-4 pt-1 gy-4">
                  <div className="col">
                    <div className="input-group has-validation">
                      <input
                        type="text"
                        className={`form-control ${
                          touched.website && !!errors.website && 'is-invalid'
                        }`}
                        placeholder="Website (optional)"
                        value={values.website}
                        onChange={handleChange('website')}
                        onBlur={handleBlur('website')}
                      />
                      {touched.website && !!errors.website && (
                        <div className="invalid-feedback">{errors.website}</div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col">
                    <div className="input-group has-validation">
                      <input
                        type="mail"
                        className={`form-control ${
                          touched.email && !!errors.email && 'is-invalid'
                        }`}
                        placeholder="E-Mail"
                        value={values.email}
                        onChange={handleChange('email')}
                        onBlur={handleBlur('email')}
                      />
                      {touched.email && !!errors.email && (
                        <div className="invalid-feedback">{errors.email}</div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col">
                    <div className="input-group has-validation">
                      <input
                        type="tel"
                        className={`form-control ${
                          touched.phone && !!errors.phone && 'is-invalid'
                        }`}
                        placeholder="Telefon (optional)"
                        value={values.phone}
                        onChange={handleChange('phone')}
                        onBlur={handleBlur('phone')}
                      />
                      {touched.phone && !!errors.phone && (
                        <div className="invalid-feedback">{errors.phone}</div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col">
                    <div className="input-group has-validation">
                      <input
                        type="text"
                        className={`form-control ${
                          touched.street && !!errors.street && 'is-invalid'
                        }`}
                        placeholder="Straße und Hausnr."
                        value={values.street}
                        onChange={handleChange('street')}
                        onBlur={handleBlur('street')}
                      />
                      {touched.street && !!errors.street && (
                        <div className="invalid-feedback">{errors.street}</div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-4">
                    <div className="input-group has-validation">
                      <input
                        type="number"
                        className={`form-control ${
                          touched.postcode && !!errors.postcode && 'is-invalid'
                        }`}
                        placeholder="PLZ"
                        value={values.postcode}
                        onChange={handleChange('postcode')}
                        onBlur={handleBlur('postcode')}
                      />
                      {touched.postcode && !!errors.postcode && (
                        <div className="invalid-feedback">
                          {errors.postcode}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-8">
                    <div className="input-group has-validation">
                      <input
                        type="text"
                        className={`form-control ${
                          touched.city && !!errors.city && 'is-invalid'
                        }`}
                        placeholder="Ort"
                        value={values.city}
                        onChange={handleChange('city')}
                        onBlur={handleBlur('city')}
                      />
                      {touched.city && !!errors.city && (
                        <div className="invalid-feedback">{errors.city}</div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="row mb-4 pt-1">
                  <div className="col">
                    <div className="input-group has-validation mb-3">
                      <input
                        key="termsOptin"
                        type="checkbox"
                        className={`form-check-input ${
                          touched.termsOptin &&
                          !!errors.termsOptin &&
                          'is-invalid'
                        }`}
                        name="termsOptin"
                        id="termsOptin"
                        checked={values.termsOptin}
                        onChange={handleChange('termsOptin')}
                        onBlur={handleBlur('termsOptin')}
                        feedback={errors.termsOptin}
                      />
                      <label className="form-check-label" htmlFor="termsOptin">
                        Ich habe die{' '}
                        <a
                          href="https://www.miniatur-wunderland.de/service/datenschutz/"
                          target="_new"
                        >
                          Datenschutzbestimmungen
                        </a>{' '}
                        zur Kenntnis genommen und willige ein, dass meine
                        Angaben auf dieser Website veröffentlicht werden dürfen.
                        Diese Einwilligung kann jederzeit widerrufen werden.
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row mb-5 pt-1">
                  <div className="col">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={false && submitDisabled}
                    >
                      Jetzt eintragen
                    </button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
      <div
        className="modal fade"
        ref={addressModalRef}
        tabIndex={-1}
        aria-labelledby="addressModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content text-dark ">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Adressfehler
              </h5>
            </div>
            <div className="modal-body">
              Wir konnten die angegebene Adresse nicht finden. So können wir Ihr
              Geschäft nicht auf der Karte anzeigen. Möchten Sie die
              Adressangaben korrigieren?
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => handleForceSubmit()}
              >
                Nein
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => addressModal.hide()}
              >
                Ja
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SignUpForm
