import React from 'react'
// import '../node_modules/bootstrap/dist/css/bootstrap.min.css'

import { Switch, Redirect, Route, useLocation } from 'react-router-dom'
import Stores from './stores/index.js'
import Site from './site/index.js'

function App() {
  let location = useLocation()
  return (
    <div className="App">
      <Switch>
        <Redirect from="/:url*(/+)" to={location.pathname.slice(0, -1)} />
        <Route exact path="/">
          <div className="container">
            <Site />
          </div>
        </Route>
        <Route path="/stores">
          <Stores />
        </Route>
      </Switch>
      <footer id="footer" className="footer-1">
        <div className="footer-copyright">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <p>
                  <small>
                    © 2021{' '}
                    <a href="https://www.miniatur-wunderland.de">
                      Miniatur Wunderland
                    </a>{' '}
                    &nbsp;{' '}
                    <a href="https://www.miniatur-wunderland.de/service/impressum/">
                      Impressum
                    </a>{' '}
                    &nbsp;{' '}
                    <a href="https://www.miniatur-wunderland.de/service/datenschutz/">
                      Datenschutz
                    </a>
                  </small>
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default App
