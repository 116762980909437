import React from 'react'

// import './../../node_modules/leaflet/dist/leaflet.css'
// import './../../node_modules/leaflet/dist/leaflet.js'
// import './App.css'
import L from 'leaflet'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'

import './leaflet.css'

import iconRetina from 'leaflet/dist/images/marker-icon-2x.png'
import icon from 'leaflet/dist/images/marker-icon.png'
import iconShadow from 'leaflet/dist/images/marker-shadow.png'

let DefaultIcon = L.icon({
  iconUrl: icon,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  shadowUrl: iconShadow,
  iconRetinaUrl: iconRetina,
  iconRetinaWidth: 25,
  iconRetinaHeight: 41,
})

L.Marker.prototype.options.icon = DefaultIcon

// const provider = new OpenStreetMapProvider()
/*
L.Icon.Default.mergeOptions({
  iconRetinaUrl: iconRetinaUrl,
  iconUrl: iconUrl,
  shadowUrl: shadowUrl,
})

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})
*/

function StoreList({ stores }) {
  const StoreMarker = ({ store }) => {
    let website = store[2].toLowerCase()

    return (
      <Marker key={'marker' + store[0]} position={store[8]}>
        <Popup>
          <h6 className="py-0 my-0">{store[1]}</h6>
          <p className="my-1">
            {store[5]}, {store[6] > 10000 ? store[6] : '0' + store[6]} {store[7]}
          </p>
          {store[4].length > 0 && (
            <p className="my-1">
              <a href={'tel:' + store[4]}>{store[4]}</a>
            </p>
          )}
          {store[2].length > 0 && (
            <p className="my-1">
              <a
                href={
                  website.substr(0, 3) === 'htt'
                    ? website
                    : website.substr(0, 3) === 'www' ||
                      (website.split('.') || []).length > 2
                    ? `http://${website}`
                    : `http://www.${website}`
                }
                target="_blank"
                rel="noreferrer"
              >
                {website}
              </a>
            </p>
          )}
          <p className="my-1">
            <a href={`mailto:${store[3]}`}>E-Mail</a>
          </p>
        </Popup>
      </Marker>
    )
  }
  const storeMarkers = stores.map((el) => {
    return el[8] && el[8].length > 0 ? (
      <StoreMarker key={'marker_' + el[0]} store={el} />
    ) : null
  })
  return (
    <div id="storelist" className="">
      <div className="row">
        <div className="col" style={{ height: '500px' }}>
          <MapContainer
            center={[50.5, 10.4]}
            zoom={5}
            scrollWheelZoom={true}
            tap={false}
            style={{ width: '90%', height: '400px', marginLeft: '5%' }}
          >
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {storeMarkers}
          </MapContainer>
        </div>
      </div>
    </div>
  )
}

export default StoreList
