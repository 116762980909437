import React, { useState, useEffect } from 'react'
import { Switch, Route, Link, useRouteMatch } from 'react-router-dom'
// import './App.css'
import logo from '../logo.svg'
import cover from '../cover.jpg'
import axios from 'axios'
import './leaflet.css'
import StoreList from './storelist.js'

function Site() {
  let match = useRouteMatch()
  const [stores, setStores] = useState([])
  const [storesLoaded, setStoresLoaded] = useState(false)

  const getStores = () => {
    axios
      .get('/storedata/stores.json')
      .then((response) => {
        setStores(response.data)
        setStoresLoaded(true)
      })
      .catch((error) => console.log(error))
  }

  useEffect(() => getStores(), [])

  return (
    <div className="site">
      <header>
        <div className="container">
          <nav
            id="navbar-site"
            className="navbar my-0 py-0 fixed-top navbar-dark navbar-expand-md"
            style={{ backgroundColor: '#7c1d1d' }}
          >
            <div className="container-fluid">
              <Link className="navbar-brand" to="/">
                <img src={logo} alt="Logo" width="210" />
              </Link>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon bi bi-list text-white"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="nav navbar-nav">
                  <li className="nav-item ">
                    <a className="nav-link" href="#buch">
                      Das Buch
                    </a>
                  </li>

                  <li className="nav-item">
                    <a className="nav-link" href="#kaufen">
                      Hier kaufen
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
          <div className="row" style={{ display: 'none' }}>
            <div className="col py-2 text-center">
              <img
                src={logo}
                className="img-fluid mx-auto"
                style={{ width: '80%', maxWidth: '400px' }}
                alt="Logo"
              />
            </div>
          </div>
        </div>
      </header>
      <Switch>
        <Route path={match.path}>
          <div className="container">
            <div
              data-bs-spy="scroll"
              data-bs-target="#navbar-site"
              data-bs-offset="0"
              tabIndex={0}
            >
              <section id="buch">
                <div style={{ paddingTop: '80px' }}>
                  <div className="row">
                    <div className="col">
                      <h1 className="display-5 mt-5 pt-3 mb-4">
                        Mitreißend, fesselnd, lustig
                      </h1>
                      <h4 className="mb-5">
                        Teil 2 der rasanten Achterbahnfahrt mitten hinein in die
                        fantastische Welt des Wunderlands ist da!
                      </h4>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-12 col-xl-11">
                      <ul className="mg">
                        <li>
                          Bahn frei für einen tollen Mix aus Freundschaft,
                          Spannung und Abenteuer - packendes Lesefutter für
                          Kinder ab 8 Jahren!
                        </li>
                        <li>
                          Es ist das perfekte Buch für jedes Kind, das schon
                          einmal mit leuchtenden Augen im Miniatur Wunderland
                          stand oder sich einen Besuch dort wünscht.
                        </li>
                        <li>
                          Denn zu diesem Buch gibt es noch etwas Besonderes zu
                          erleben: Diese Geschichte wird Wirklichkeit in
                          Deutschlands beliebtester Touristenattraktion! Szenen
                          aus dem ersten Band und die Charaktere dieser
                          Buchreihe finden sich ab Erscheinen des Buchs im
                          Miniatur Wunderland wieder und können dort bestaunt
                          werden.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </section>
              <section>
                <div className="row">
                  <div className="col">
                    <h2 className="mt-5 pt-1 mb-4">Die Geschichte</h2>
                  </div>
                </div>
                <div className="row  justify-content-around">
                  <div className="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-3 pr-4 pr-md-0">
                    <img src={cover} alt="Cover" className="img-fluid" />
                  </div>
                  <div className="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-7">
                    <p>
                      Die Jagd nach der gestohlenen magischen Modellbahn ihres
                      Opas führt die Geschwister Olivia, Bruno, Tom und ihre
                      Freundin Pi im spektakulären Bangalore Express über die
                      Alpen bis nach Venedig. Dort treffen sie auf ihren alten
                      Widersacher Magnus, der einen finsteren Plan verfolgt: Er
                      will mit dem magischen Teil sogar die Naturgesetze aus den
                      Angeln heben. Ob es den vieren gelingen wird, seine Pläne
                      zu durchkreuzen? Können sie Venedig vor einer Katastrophe
                      retten? Klar ist: Das Abenteuer ist noch lange nicht zu
                      Ende!
                    </p>
                    <p>
                      Bahn frei für den 2. Band der Wunderland-Abenteuerreihe:
                      ein absolut großartiger Mix aus Freundschaft, Spannung &
                      Abenteuer!
                    </p>
                    <p>
                      Er schließt direkt an die Geschichte aus dem ersten Band
                      an, in welchem die vier Helden in ihrer Heimatstadt
                      Knuffingen ein atemberaubendes Abenteuer erlebt haben.
                    </p>
                  </div>
                </div>
              </section>

              <section>
                <div id="kaufen" className="row mt-5">
                  <div className="col">
                    <h2 className="mt-4 mb-3">
                      Lassen Sie uns dem Buchhandel helfen.
                    </h2>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <p>
                      Wie der gesamte Einzelhandel haben auch die Buchläden sehr
                      unter dem Lockdown gelitten. Wir würden uns deshalb sehr
                      freuen, wenn Sie das Buch nicht bei den bekannten
                      Online-Giganten bestellen, sondern beim Buchladen Ihres
                      Vertrauens. Viele davon haben eigene Online-Shops oder
                      nehmen telefonisch Bestellungen an.
                    </p>
                    <p>
                      Unten finden Sie einige davon - vielleicht gibt es ja auch
                      einen in Ihrer Nähe:
                    </p>
                    {storesLoaded ? (
                      <StoreList stores={stores} />
                    ) : (
                      <p>Lädt...</p>
                    )}
                  </div>
                </div>
              </section>
            </div>
          </div>
        </Route>
      </Switch>
    </div>
  )
}

export default Site
